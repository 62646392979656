<template>
  <div class="login" v-loading="loading">
    <p class="login_title">登录</p>
    <div class="login_input">
      <el-input v-model="formData.account" placeholder="请输入登录账号"></el-input>
    </div>
    <div class="login_input">
      <el-input type="password" v-model="formData.password" placeholder="请输入登录密码"></el-input>
    </div>
    <p class="login_btn" @click="submit" >登录</p>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        formData:{
          account:"",
          password:""
        },
        loading:true
      }
    },
    methods:{
      // 发起登录
      submit(){

      },
    },
  }
</script>

<style  lang="scss" scoped>
@import "@/assets/style/basic.scss";
.login{
  position: fixed;
  width: 380px;
  height: 380px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin:auto;
  padding: 40px 30px 50px;
  box-sizing: border-box;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  &_title{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    margin-bottom: 60px;
  }
  &_input{
    display: flex;
    margin-bottom: 30px;
  }
  &_btn{
    // width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    @include background-main-color($theme_sky);
  }
}
</style>